<script setup>
import HeaderWithoutButton from '../components/shared-page-components/HeaderWithoutButton.vue'
import TheFooter from '../components/shared-page-components/TheFooter'
import router from '../router'
function goBackToHomePage() {
  router.push({ name: 'Home'})
}
</script>

<template>
  <div class="main-container">
    <HeaderWithoutButton />
    <div class="questions-container">
      <div class="container page-not-found">
        <div class="top-text">404</div>
        <div class="bottom-text">page not found</div>
          <div
              @click="goBackToHomePage"
              class="button"
          >
            Go to Home
          </div>
      </div>
    </div>
  </div>
  <TheFooter />
</template>

<style lang="scss" scoped>
@import '../assets/style/main';
.main-container {
  display:flex;
  flex-direction: column;
}
.questions-container {
  min-height: 280px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .page-not-found {
    text-align: center;
    font-family: $font-poppins;
    .top-text {
      color: $red;
      font-size: 140px;
      line-height: 130px;
    }
    .bottom-text {
      color: $dark-blue;
      font-size: 32px;
    }

    .button {
      background: -moz-linear-gradient(180deg, #ff001a, #911f2b);
      background: -webkit-linear-gradient(180deg, #ff001a, #911f2b);
      background: linear-gradient(180deg, #ff001a, #911f2b);
      border: none;
      font-size: 24px;
      font-weight: 500;
      border-radius: 5px;
      font-family: $font-poppins;
      color: white;
      padding: 10px;
      max-width: 250px;
      margin: 12px auto;
      cursor: pointer;
      &:hover {
        background: -moz-linear-gradient(0deg, #ff001a, #911f2b);
        background: -webkit-linear-gradient(0deg, #ff001a, #911f2b);
        background: linear-gradient(0deg, #ff001a, #911f2b);
      }

    }
  }
}

@media only screen and (max-width: 430px) {
  .questions-container {
    .page-not-found {
      .top-text {
        font-size: 95px;
        line-height: 90px;
      }
      .bottom-text {
        font-size: 25px;
      }
      .button {
        max-width: 200px;
      }
    }
  }
}
</style>